:root, [data-amplify-theme] {

  --amplify-colors-red-10: hsl(354, 75%, 70%);
  --amplify-colors-red-20: hsl(354, 75%, 65%);
  --amplify-colors-red-40: hsl(354, 75%, 60%);
  --amplify-colors-red-60: hsl(354, 50%, 55%);
  --amplify-colors-red-80: hsl(354, 95%, 50%);
  --amplify-colors-red-90: hsl(354, 100%, 45%);
  --amplify-colors-red-100: hsl(354, 100%, 40%);


  --amplify-colors-pink-10: hsl(333, 80%, 70%);
  --amplify-colors-pink-20: hsl(333, 80%, 65%);
  --amplify-colors-pink-40: hsl(333, 80%, 60%);
  --amplify-colors-pink-60: hsl(333, 80%, 55%);
  --amplify-colors-pink-80: hsl(333, 80%, 50%);
  --amplify-colors-pink-90: hsl(333, 80%, 45%);
  --amplify-colors-pink-100: hsl(333, 80%, 40%);

  --amplify-colors-neutral-10: hsl(0, 0, 96%);
  --amplify-colors-neutral-20: hsl(0, 0, 92%);
  --amplify-colors-neutral-40: hsl(0, 0, 88%);
  --amplify-colors-neutral-60: hsl(0, 0, 84%);
  --amplify-colors-neutral-80: hsl(0, 0, 80%);
  --amplify-colors-neutral-90: hsl(0, 0, 76%);
  --amplify-colors-neutral-100: hsl(0, 0, 72%);

  --amplify-components-button-hover-background-color: var(--amplify-colors-neutral-10);
  --amplify-components-button-link-hover-background-color: var(--amplify-colors-neutral-10);
  --amplify-components-button-active-background-color: var(--amplify-colors-neutral-10);
  --amplify-components-button-link-active-background-color: var(--amplify-colors-neutral-10);
  --amplify-components-button-link-focus-background-color: var(--amplify-colors-neutral-10);

  --amplify-components-button-hover-border-color: var(--amplify-colors-neutral-10);
  --amplify-components-button-link-hover-border-color: var(--amplify-colors-neutral-10);
  --amplify-components-button-active-border-color: var(--amplify-colors-neutral-10);
  --amplify-components-button-link-active-border-color: var(--amplify-colors-neutral-10);
  --amplify-components-button-link-focus-border-color: var(--amplify-colors-neutral-10);

  --amplify-colors-brand-primary-10: var(--amplify-colors-pink-10);
  --amplify-colors-brand-primary-20: var(--amplify-colors-pink-20);
  --amplify-colors-brand-primary-40: var(--amplify-colors-pink-40);
  --amplify-colors-brand-primary-60: var(--amplify-colors-pink-60);
  --amplify-colors-brand-primary-80: var(--amplify-colors-pink-80);
  --amplify-colors-brand-primary-90: var(--amplify-colors-pink-90);
  --amplify-colors-brand-primary-100: var(--amplify-colors-pink-100);

  --amplify-colors-brand-secondary-10: var(--amplify-colors-red-10);
  --amplify-colors-brand-secondary-20: var(--amplify-colors-red-20);
  --amplify-colors-brand-secondary-40: var(--amplify-colors-red-40);
  --amplify-colors-brand-secondary-60: var(--amplify-colors-red-60);
  --amplify-colors-brand-secondary-80: var(--amplify-colors-red-80);
  --amplify-colors-brand-secondary-90: var(--amplify-colors-red-90);
  --amplify-colors-brand-secondary-100: var(--amplify-colors-red-100);

  --amplify-components-alert-error-color: var(--amplify-colors-neutral-10);
}

body {
  background: #ffffff;
  font-family: Open Sans,sans-serif;

  --amplify-components-heading-3-font-size: 36px;
  --amplify-components-heading-color: rgb(51, 51, 51);
  --amplify-components-authenticator-container-width-max: 60%;
  margin: 0;
}

#info-panel {
  background-image: url('https://platform.alpha.webgains.team/assets/images/login-picture.jpg');
  background-size: cover;
  height: 100vh;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

#setup-totp-modal, #confirm-sign-in-modal {
  background-color: rgba(255,255,255, 0.8);
  backdrop-filter: blur(5px);
  border-radius: 10px;
  width: 40%;
  padding: 2em;
  position: fixed;
  top: 5%;
  right: 5%;
  max-height: 90vh;
  overflow-y: auto;
}

@media (min-width: 1152px) {
  [data-amplify-authenticator] [data-amplify-container] {
    place-self: start;
    margin-left: 10%;
    margin-top: 2%;
    width: 80%;
  }
}

@media (max-width: 1152px) {
  body {
    --amplify-components-authenticator-container-width-max: 100%;
  }
  #setup-totp-modal, #confirm-sign-in-modal {
    position: initial;
    border: 4px solid #e31e79;
    border-radius: 10px;
    margin: 5%;
    width: 90%;
    padding: 3em;
    max-height: 200vh;
  }
  #info-panel {
    display: none;
  }
}

[data-amplify-authenticator] [data-amplify-router] {
  border: none;
  box-shadow: none;
  background-color: transparent;
}

.amplify-input {
  border-color: rgba(1,3,1,.1);
  font-size: 14px;
}

.amplify-button.amplify-field__show-password {
  border-color: rgba(1,3,1,.1);
  color: rgb(255, 255, 255);
}

.amplify-button--link {
  color: var(--amplify-components-button-link-hover-color);
}

.amplify-input::placeholder, .amplify-icon {
  color: rgba(0,0,0,.46);
}

.amplify-label {
  color: #9da39d;
  font-size: 14px;
  font-weight: 700;
}

.amplify-text {
  font-size: 11px;
}

.subheader {
  padding: var(--amplify-space-medium) 0 var(--amplify-space-medium) var(--amplify-space-medium);
  font-size: 14px;
  text-align: justify;
}

.subheader-small {
  padding: var(--amplify-space-xs) 0 var(--amplify-space-xs) var(--amplify-space-xs);
  font-size: 14px;
  text-align: justify;
}

.amplify-menu-content__item {
  color: #444!important;
}

.mfaicon {
  border: 4px solid #e31e79;
  color: #e31c24;
  border-radius: 50% 50%;
  height: 64px;
  width: 64px;
  display: block!important;
  text-align: center;
  padding: 3px 0 0 0;
}

.mfasetupsteps td {
  border: unset;
  font-size: 13px;
}

.mfasetuptdicons {
  float: right;
}

.amplify-flex > img {
  margin: 0 25%;
}